.blog {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
        flex-direction: row;

    }
}

.blog__search {
    padding: 15px;

    input {
        max-width: 450px;
        width: 100%;
        border-radius: 25px;
        padding: 15px;
    }
}

.noData {
    align-items: center;
    justify-content: center;
}

.left__side {
    flex: 3 1;
}