// .write {
//     padding-top: 80px;
// }

// .writeImg {
//     margin-left: 150px;
//     width: 70vw;
//     height: 250px;
//     border-radius: 10px;
//     object-fit: cover;
// }

// .writeForm {
//     position: relative;
// }

// .writeFormGroup {
//     margin-left: 150px;
//     display: flex;
//     align-items: center;
// }

// .writeIcon {
//     cursor: pointer;
//     width: 40px;
//     height: 40px;

//     path {
//         stroke: #1c1c1c;
//     }
// }

// .writeInput {
//     font-size: 30px;
//     border: none;
//     padding: 20px;
//     width: 70vw;

//     &:focus {
//         outline: none;
//     }
// }

// .writeText {
//     font-size: 20px;
//     font-weight: 300;
//     height: 100vh;
// }

// .writeSubmit {
//     position: absolute;
//     top: 20px;
//     right: 50px;

//     background: teal;
//     color: white;
//     padding: 10px;
//     border: none;
//     border-radius: 10px;
// }

// .quill {
//     width: 70vw;
// }

.add {
  margin-top: 80px;
  display: flex;
  gap: 20px;

  .content {
    flex: 5;
    display: flex;
    flex-direction: column;
    gap: 20px;

    input {
      padding: 10px;
      border: 1px solid lightgray;
    }

    .editorContainer {
      height: 300px;
      overflow: scroll;
      border: 1px solid lightgray;

      .editor {
        height: 100%;
        border: none;
      }
    }
  }

  .menu {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .item {
      border: 1px solid lightgray;
      padding: 15px;
      border-radius: 10px;
      // flex: 1;
      display: flex;
      gap: 15px;
      flex-direction: column;
      justify-content: space-between;
      font-size: 12px;
      color: #555;

      h1 {
        font-size: 20px;
      }

      .file {
        color: tomato;
        font-size: 15px;
        text-decoration: underline;
        cursor: pointer;
      }

      .buttons {
        display: flex;
        justify-content: space-between;

        :first-child {
          cursor: pointer;
          color: var(--default-color);
          background-color: white;
          border: 1px solid var(--default-color);
          padding: 10px 5px;
        }

        :last-child {
          cursor: pointer;
          color: white;
          background-color: var(--default-color);
          border: 1px solid var(--default-color);
          padding: 5px 25px;
        }
      }

      .cat {
        display: flex;
        align-items: center;
        gap: 2px;
        color: var(--default-color);

        label {
          font-size: 14px;
          margin-left: 7px;
        }
      }
    }
  }
}