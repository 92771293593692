/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 340px) {
    .container {
        margin-inline: 1rem;
    }

    .section {
        padding-block: 4rem 1rem;
    }

    .home__title {
        font-size: 2rem;
    }


    .industry {
        &__content {
            padding: 6rem 1rem 2rem;
        }

        &__form {
            flex-direction: column;
            background: none;
            row-gap: 1rem;
        }

        &__input {
            width: initial;
            padding: 1rem;
            border-radius: 4rem;
        }

        &__button {
            justify-content: center;
        }
    }

    .footer__content {
        gap: 1.5rem;
    }
}

/* For medium devices */

@media screen and (max-width: 576px) {
    .industry {
        &__steps {
            grid-template-columns: unset !important;
        }

        &__img {
            opacity: 0.1;
        }
    }

    .popular__container {
        row-gap: 2rem !important;
    }

    .contact {
        &__data {
            flex-direction: column;
            align-items: flex-start !important;

            hr {
                display: none;
            }
        }

        &__block {
            min-height: auto !important;

            &:first-child {
                flex-direction: row-reverse;

                .contact__detail {
                    align-items: flex-start !important;
                }
            }
        }
    }

    .post {
        width: 100% !important;
        margin: 0 !important;
    }
}

@media screen and (min-width: 576px) {

    .home__container,
    .about__container,
    .industry__content,
    .customer__content {
        grid-template-columns: 0.7fr;
        justify-content: center;
    }

}

@media screen and (min-width: 767px) {
    .nav {
        column-gap: 3rem;
    }

    .nav__toggle,
    .nav__close,
    .nav__img-1,
    .nav__img-2 {
        display: none !important;
    }

    .nav__list {
        flex-direction: row !important;
        column-gap: 2rem;
    }

    .nav__menu {
        margin-left: auto;
    }

    .dark-theme .nav__menu {
        box-shadow: none;
    }


    .popular__container {
        grid-template-columns: repeat(2, 250px);
    }

    .footer__content {
        grid-template-columns: repeat(4, max-content);
    }
}

/* For large devices */
@media screen and (min-width: 1024px) {
    .section {
        padding-block: 5rem 3.5rem;
    }

    .section__subtitle {
        margin-bottom: 0rem;
    }

    .home {
        &__container {
            position: relative;
            grid-template-columns: 524px 600px;
            padding-block: 7rem 3rem !important;
        }

        &__data {
            text-align: initial !important;
            order: -1;
        }

        &__title {
            img {
                width: 60px;
            }

            div {
                justify-content: initial !important;
                column-gap: 1rem !important;
            }
        }

        // &__description {
        //     padding-inline: 0 10rem !important;
        // }

        // &__img {
        //     width: 600px !important;
        //     position: absolute;
        //     top: 5rem;
        //     right: -5rem;
        //     bottom: 17%;
        // }
    }

    .about {
        &__container {
            grid-template-columns: 450px 624px;
            align-items: center;
            column-gap: 7rem;
        }

        &__data {
            order: 1;
            text-align: initial !important;
        }

        .section__subtitle,
        .section__title {
            text-align: initial !important;
        }

        &__title {
            img {
                width: 50px;
            }
        }

        &__img {
            width: 450px !important;
        }
    }

    .popular {
        &__container {
            grid-template-columns: repeat(4, 262px) !important;
            column-gap: 3.5rem;
            padding-block: 4rem 6rem;
        }

        &__card {
            padding: 7.5rem 2rem 2rem;
            border-radius: 1.5rem;
        }

        &__button {
            bottom: 2rem;
        }
    }

    .recently {
        &__container {
            grid-template-columns: 350px 480px;
            align-items: center;
            column-gap: 7rem;
            padding-bottom: 2rem;
        }

        &__data {
            text-align: initial !important;

            &-img {
                width: 30px;
                top: 5.5rem;
                right: 2.5rem;
            }
        }

        .section__title {
            text-align: initial !important;
        }

        &__img {
            width: 480px;
        }
    }

    .industry {
        &__content {
            // grid-template-columns: 650px 160px;
            grid-template-columns: 1024px;
            align-items: center;
            column-gap: 6rem;
            padding: 2.5rem 0;
            border-radius: 0rem;
        }

        &__img {
            /* position: initial; */
            width: 1050px;
            opacity: 0.5;
        }

        .section__subtitle,
        .section__title {
            text-align: initial !important;

        }

        &__form {
            padding: 8px 8px 8px 24px;
        }

        &__button {
            font-size: var(--normal-font-size);
        }
    }

    .customer {
        &__content {
            grid-template-columns: 550px 0px;
            align-items: center;
            padding-bottom: 2rem;
        }

        &__detail {
            a {
                font-size: var(--h2-font-size);
            }

        }
    }


    .scrollup {
        right: 3rem;
    }

}

@media screen and (min-width: 1064px) {
    .container {
        margin-inline: auto;
    }
}