.sideBar {
    flex: 3;
    margin: 20px;
    background: #fff;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
}

.sidebarItems {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sideBarTitle {
    display: block;

    margin: 10px 0;
    padding: 5px 0;
    width: 100%;

    // border-top: 1px solid #a7a4a4;
    border-bottom: 2px solid #a7a4a4;

    font-size: 14px;
    color: #222;
    font-weight: 600;
}