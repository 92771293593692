.register {
    background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
    height: calc(100vh - 50px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.registerTitle {
    font-size: 50px;
}

.registerForm {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    &>label {
        margin: 10px 0;
    }
}

.registerInput {
    padding: 10px;
    // border: none;
}

.registerButton {
    margin-top: 20px;
    cursor: pointer;
    background-color: #0089D0;
    border: none;
    color: #ffffff;
    ;
    border-radius: 10px;
    padding: 10px;
}

.registerLoginButton {
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: transparent;
    cursor: pointer;
    border: 1px solid #1c1c1c;
    ;
    padding: 10px;
    color: #1c1c1c;
    ;
    border-radius: 10px;
}