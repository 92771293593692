/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;700&display=swap");
@import './variables';
@import './theme';

* {
    font-family: 'Lexend', sans-serif;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}


html {
    scroll-behavior: smooth;
}

body {
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    background-color: var(--body-color);
    color: var(--text-color);
    transition: background 0.4s;
    /* for dark mode animation */
}

h1,
h2,
h3 {
    color: var(--title-color);
    font-weight: var(--font-medium);
}

ul {
    list-style: none;
}

a {
    color: $default-color;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

}

img {
    max-width: 100%;
    height: auto;
}

.ql-editor {
    height: 550px !important;
    max-height: 550px;
    overflow: auto;
}

input {
    border: 1px solid lightgray !important;
    padding: 10px;

    font-size: 16px;

    &:focus {
        outline: none;
    }

    &:hover {
        border: 1px solid $default-color !important;

    }
}

/*=============== REUSABLE CSS CLASSES ===============*/
.container {
    max-width: 1250px;
    margin-inline: 1.5rem;

    /* Tuong duong  */
    /* margin-left: 1.5rem;
    margin-right: 1.5rem; */
}

.grid {
    display: grid;
    gap: 1.5rem;
}

.section {
    padding-block: 5rem 1rem;

    /* Tuong duong  */
    /* padding-top: 5rem;
    padding-bottom: 1rem; */
}

.section__title {
    font-size: var(--h1-font-size);
    font-family: var(--title-font);
    margin-bottom: 1.5rem;
    font-weight: 700;
}

.section__subtitle {
    display: block;
    font-size: var(--normal-font-size);
    font-family: var(--subtitle-font);
    color: var(--first-color);
    margin-bottom: 0.5rem;

    text-transform: uppercase;
}

.section__title,
.section__subtitle {
    text-align: center;
}

.main {
    overflow: hidden;
    /* For animation ScrollReveal */
}


.button {
    display: inline-flex;
    align-items: center;
    column-gap: 0.5rem;
    background-color: var(--first-color);
    padding: 1rem 1.5rem;
    border-radius: 4rem;
    color: #fff;
    font-weight: var(--font-medium);
    transition: background 0.3s;
}

.button i {
    font-size: 1.5rem;
    transition: transform 0.3s;
}

.button:hover {
    background-color: var(--first-color-alt);
}

.button i:hover {
    transform: translateX(0.25rem);
}

.nav__btn {
    cursor: pointer;
}