/* Variable */
:root {
    // dynamic config
    --white: #fff;
    --black: #1c1c1c;
    --default-color: #0089D0;
    --green-lv-1: #80c4e8;
    --green-lv-2: #c0e2f4;
    --green-lv-3: #e0f1fa;
    --green-lv-4: #f0f8fd;
}

$white : var(--white);
$black : var(--black);
$default-color : var(--default-color);
$green-lv-1 : var(--green-lv-1);
$green-lv-2 : var(--green-lv-2);
$green-lv-3 : var(--green-lv-3);
$green-lv-4 : var(--green-lv-4);

/*=============== VARIABLES CSS ===============*/
:root {
    $root: 16;

    --header-height: 3.5rem;

    --first-color: hsla(201, 99%, 41%, 1);
    --first-color-alt: hsla(198, 88%, 46%, 1);
    --title-color: hsl(19, 16%, 15%);
    --text-color: hsl(19, 16%, 35%);
    --text-color-light: hsl(19, 8%, 55%);
    --body-color: #ffffff;
    --container-color: hsl(19, 100%, 97%);
    --white-color: #ffffff;
    --black-color: hsla(0, 0%, 0%, 1);
    --section-bg-color: hsla(203, 76%, 97%, 1);
    --hr-color: hsla(0, 0%, 11%, 0.17);
    /*========== Font and typography ==========*/
    /*.5rem = 8px | 1rem = 16px ...*/
    --body-font: "Lexend", sans-serif;
    --title-font: "Lexend", serif;
    --subtitle-font: "Lexend", cursive;
    --biggest-font-size: 2.25rem;
    /*36px*/
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    /*15px*/
    --small-font-size: 0.813rem;
    /*13px*/
    --smaller-font-size: 0.75rem;
    /*12px*/

    /*========== Font weight ==========*/
    --font-medium: 500;
    --font-semi-bold: 600;

    /*========== z index ==========*/
    --z-tooltip: 10;
    --z-fixed: 100;
}

@media screen and (min-width: 1024px) {
    :root {
        --biggest-font-size: 3rem;
        --h1-font-size: 2.25rem;
        --h2-font-size: 1.5rem;
        --h3-font-size: 1.25rem;
        --normal-font-size: 1rem;
        --small-font-size: 0.875rem;
        --smaller-font-size: 0.813rem;
    }
}