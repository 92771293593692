/*=============== THEME ===============*/
.nav__buttons {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.change__theme {
    font-size: 1.25rem;
    color: var(--title-color);
    cursor: pointer;
    transition: color 0.3s;
}

/*========== Variables Dark theme ==========*/

body.dark-theme {
    --first-color: hsla(201, 99%, 41%, 1);
    /* --first-color-alt: hsl(19, 64%, 54%); */
    --title-color: hsl(19, 24%, 85%);
    --text-color: hsl(19, 16%, 65%);
    --body-color: hsl(19, 12%, 8%);
    --container-color: hsl(19, 10%, 10%);
    --section-bg-color: hsl(19, 12%, 8%);
    --black-color: #ffffff;
    --card-color: hsl(0, 5%, 22%);
    --white-color: hsla(0, 0%, 0%, 1);
    --hr-color: hsla(201, 99%, 41%, 1);
}

/*========== 
      Color changes in some parts of 
      the website, in light theme
  ==========*/
.dark-theme .bg-header,
.dark-theme .nav__menu,
.dark-theme .scrollup {
    box-shadow: 0 2px 8px hsla(19, 64%, 4%, 0.5);
}

.dark-theme .popular__card {
    box-shadow: 0 8px 20px hsla(19, 64%, 4%, 0.2);
    background: var(--card-color);
}

.dark-theme .newsletter__form,
.dark-theme .newsletter__input {
    background-color: var(--body-color);
}

.dark-theme::-webkit-scrollbar {
    background-color: hsl(19, 8%, 15%);
}

.dark-theme::-webkit-scrollbar-thumb {
    background-color: hsl(19, 8%, 25%);
}

.dark-theme::-webkit-scrollbar-thumb:hover {
    background-color: hsl(19, 8%, 35%);
}