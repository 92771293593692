/* CreatePost.css */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.popup-inner {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;

    h2 {
        margin-bottom: 20px;
    }

    .btn__add {
        max-width: 150px;
        width: 100%;

        float: right;

        justify-content: center;
        margin: 0;
    }

    .btn__cancel {
        max-width: 150px;
        width: 100%;

        float: left;

        justify-content: center;
        margin: 0;
        padding: 15px;

        border-radius: 10px;

        font-size: 16px;

        background: #ffffff;
        color: var(--first-color);
        border: 1px solid var(--first-color);
        cursor: pointer;
    }

    .file {
        display: flex;
        align-items: center;
        gap: 5px;

        margin-top: 10px;

        color: var(--first-color);
        cursor: pointer;

    }
}

.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;

    label {
        margin-bottom: 0.5rem;
    }

    input {
        width: 100%;
    }

    textarea {
        border: 1px solid lightgray !important;
        padding: 10px;

        width: 100%;

        &:hover {
            border-color: var(--first-color);
        }
    }
}