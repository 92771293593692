.singlePost {
    flex: 9;
}

.singlePostWrapper {
    padding: 20px;
    padding-right: 0;
}

.singlePostImg {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 5px;
}

.singlePostTitle {
    margin: 15px 0;
}

.singlePostInfo {
    margin: 10px 0;

    display: flex;
    align-items: flex-start;
    flex-direction: column;

    color: #c9c9c9;
}

.singlePostEdit {
    float: right;
    margin: 10px 0;
}

.singlePostIcon {
    margin-left: 10px;
    cursor: pointer;
}

.singlePostDesc {
    font-size: 16px;
    line-height: 24px;

    &::first-letter {
        margin-left: 20px;
        font-size: 30px;
        font-weight: 600;
    }

    img {
        margin: 10px auto;
        text-align: center;
        width: 100%;
        padding: 25px;
    }
}

.singleShare {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-direction: row;

    span {
        display: block;
        margin-right: 10px;
    }
}