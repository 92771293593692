.post {
    width: 385px;
    margin: 0px 25px 40px 25px;
}

.postImg {
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 7px;
}

.postInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .link {
        text-decoration: none;
    }
}

.postCat {
    font-size: 12px;
    color: #be9656;
    line-height: 20px;
    margin-top: 15px;
    margin-right: 10px;
    cursor: pointer;
}

.postTitle {
    font-size: 18px;
    font-weight: 700;
}

.postDesc {
    color: #444;
    line-height: 24px;
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.postDate {
    color: #c9c9c9;
}

a:hover {
    text-decoration: none !important;
}